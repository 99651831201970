import { render, staticRenderFns } from "./NewBodyPrincipal.vue?vue&type=template&id=0cd8ad98&scoped=true"
import script from "./NewBodyPrincipal.vue?vue&type=script&lang=js"
export * from "./NewBodyPrincipal.vue?vue&type=script&lang=js"
import style0 from "./NewBodyPrincipal.vue?vue&type=style&index=0&id=0cd8ad98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd8ad98",
  null
  
)

export default component.exports