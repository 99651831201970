export default {
  materia: [
    {
      titulo: "CAPA",
      resumo: "Código de rastreio: L0G1ST1C@",
      imgAutor: require("../assets/imgs/autores/helena.jpg"),
      nomeAutor: "Helena Canhoni",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "Hoje, ao entrar em um site de e-commerce, o prazo de entrega é um fator determinante para a finalização (ou não) da compra. Segundo o CX Trends 2024, 54% dos consumidores consideram o tempo de recebimento ao comprar virtualmente. Apesar de estarmos há séculos das grandes navegações europeias, as variáveis no momento de fazer uma entrega são similares. As empresas logísticas ainda precisam considerar clima, terreno, tempo de entrega e transporte até o cliente. ",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_83/500x500px-CAPA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "ENTREVISTA",
      imgAutor: require("../assets/imgs/autores/lucas.kina.jpg"),
      nomeAutor: "Lucas Kina",
      resumo: "Vender, comunicar e socializar ",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "A aplicação de mídias sociais e tecnologias de conversação no e-commerce ganham espaço no dia-a-dia de quem consome. O fluxo, que antes se desenhava como um funil, tornou-se uma espiral de caminhos e possibilidades. Nesta entrevista, Renato Avelar, sócio e co-CEO da A&EIGHT, explora as melhores práticas para implementar estratégias eficazes nas duas estratégias.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_83/500x500px-ENTREVISTA.png"),
      active: true,
      viewModal: false,
    },
    {
      titulo: "CASE",
      imgAutor: require("../assets/imgs/autores/julia.rondinelli.jpg"),
      nomeAutor: "Júlia Rondinelii",
      resumo: "Out of the blue",
      cargoAutor: "E-Commerce Brasil",
      conteudo:
        "Desvendar o futuro e dar uma forcinha à imaginação são algumas das estratégias do Pinterest para se tornar um ‘ambiente’ seguro, aconchegante e verdadeiro para anunciantes e consumidores. Rogério Nicolai, diretor de negócios do Pinterest no Brasil, conversou com a redação do E-Commerce Brasil e explicou quais tendências de fato engajaram vendedores e consumidores brasileiros em 2024 e o que podemos esperar para as tendências de 2025.",
      tempLeitura: "2",
      imgMateria: require("../assets/imgs/imgs_83/500x500px.png"),
      active: true,
      viewModal: false,
    },
  ],
};
